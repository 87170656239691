<template>
    <Main :show-title="isLoading || hasQuestions">

        <Tabs v-if="!!closedQuestions.length" :tabs="tabs" v-model="view"/>

        <CardList v-if="isLoading" :columns="2">
            <Placeholder element="li" :classes="['card column-1']" :height="114"/>
            <Placeholder element="li" :classes="['card column-1']" :height="114"/>
            <Placeholder element="li" :classes="['card column-2']" :height="114"/>
        </CardList>

        <template v-else-if="hasQuestions">
            <CardList :columns="2" v-show="view === 'open'">
                <QuestionCard v-for="question in openQuestions" :key="question.id" v-bind="question"/>
            </CardList>

            <CardList :columns="2" v-show="view === 'closed'">
                <QuestionCard v-for="question in closedQuestions" :key="question.id" v-bind="question"/>
            </CardList>
        </template>

        <Empty v-else-if="!subscription.isActive" illustration="questions">
            <template #title>Intet abonnement</template>
            <template #description>Tilknyt
                <span @click="$router.push('/settings')" class="link">abonnement</span>
                til konto
            </template>
        </Empty>
        <Empty v-else-if="subscription.plan !== 'pro'" illustration="questions">
            <template #title>Kræver pro</template>
            <template #description>Opgradér dit
                <span @click="$router.push('/settings')" class="link">abonnement</span>
                til pro
            </template>
        </Empty>
        <Empty v-else illustration="questions">
            <template #title>Der er endnu ikke stillet spørgsmål</template>
            <template #description>Stil dit
                <router-link to="/questions/create">første</router-link>
                spørgsmål
            </template>
        </Empty>

    </Main>

    <ActionButton icon="plus" :float="true" @click="create" :disabled="!subscription.isActive || subscription.plan !== 'pro'" :tooltip="buttonTooltip"/>

    <ModelRouterView/>
</template>

<script>
import Main from '@/components/Main.vue';
import Empty from '@/components/Empty.vue';
import ModelRouterView from '@/components/ModelRouterView.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import Tabs from '@/components/Tabs.vue';
import useState from '@/store';
import { computed, onMounted, reactive } from 'vue';
import CardList from '@/components/CardList.vue';
import QuestionCard from '@/components/cards/QuestionCard.vue';
import Placeholder from '@/components/Placeholder.vue';
import useTickets from '@/utils/useTickets';

export default {
    name: 'Questions',
    components: {
        Main,
        Empty,
        ModelRouterView,
        ActionButton,
        Tabs,
        CardList,
        QuestionCard,
        Placeholder,
    },
    setup() {
        const { tickets, updateTickets, project, subscription } = useState();
        const freshTickets = reactive({ open: [], closed: [] });

        const isLoading = computed(() => tickets.value.isLoading);
        const openQuestions = computed(() => (freshTickets.open.length ? freshTickets.open : tickets.value.open));
        const closedQuestions = computed(() => (freshTickets.closed.length ? freshTickets.closed : tickets.value.closed));
        const hasQuestions = computed(() => !![...tickets.value.open, ...tickets.value.closed].length);

        onMounted(async () => {
            if (!isLoading.value) {
                const response = await useTickets(project.value.id);
                freshTickets.open = response.tickets.value.open;
                freshTickets.closed = response.tickets.value.closed;
                updateTickets(freshTickets);
            }
        });

        return { openQuestions, closedQuestions, isLoading, hasQuestions, freshTickets, updateTickets, subscription };
    },
    data() {
        return {
            view: 'open',
            tabs: [
                { value: 'open', label: 'Åbne' },
                { value: 'closed', label: 'Arkiverede' },
            ],
        };
    },
    watch: {
        $route(to, from) {
            if (from.name === 'create-question' && Object.keys(to.params).length !== 0) {
                const ticket = {
                    ...to.params,
                    id: Number(to.params.id),
                    column: 1,
                    statusColor: 'yellow',
                    statusText: 'Afventer svar',
                };

                this.freshTickets.open = [ticket, ...this.openQuestions];
                this.updateTickets(this.freshTickets);
            }
        },
    },
    computed: {
        buttonTooltip() {
            if (this.subscription.plan === 'pro') return 'Stil spørgsmål';
            return this.subscription.isActive ? 'Opgradér til pro abonnement, for at stile et spørgsmål' : 'Tilknyt et abonnement til din konto, for at stil et spørgsmål';
        },
    },
    methods: {
        create() {
            this.$router.push({ name: 'create-question' });
        },
    },
};
</script>
