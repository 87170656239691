<template>
    <header class="card">
        <small v-if="label">{{ label }}:</small>
        <span v-for="tab in tabs" :key="tab.value"
              :class="`status-label ${view === tab.value ? 'green' : 'light-grey'}`"
              @click="view = tab.value">{{ tab.label }}
        </span>
    </header>
</template>

<script>
export default {
    name: 'Tabs',
    props: {
        label: {
            type: String,
            default: null,
        },
        tabs: {
            type: Array,
            required: true,
        },
        modelValue: String,
    },
    emits: ['update:modelValue'],
    computed: {
        view: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
};
</script>

<style scoped lang="less">
header {
    display: flex;
    align-items: center;
    margin-bottom: 60px;

    small {
        margin-right: 16px;
    }

    span.status-label {
        min-width: 65px;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}
</style>
